$Background: #fff;
$BackgroundPink: #f3d3eb;
$MainColor: #d28ca1;
$HoverColor: #241b1e;
$ActiveColor: #e7ced6;
$Shadow: #000000;
$BackgroundCard: #ffeafe;
$Grey: rgba(151, 145, 145, 0.808);
$Submit: #bc8f8f;
$SubmitHover: #ddadaf;
$DisabledColor: #dbdbdb;
$DisabledTextColor: #c0c0c0;
$Error: red;
$Success: rgb(16, 173, 16);
