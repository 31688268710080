@import "../../assets/scss/Colors.module.scss";
@import "../../assets/scss/Fonts.module.scss";

.popupContainer {
  text-align: center;

  .buttonClose {
    border: 1px solid $Grey;
    border-radius: 5px;
    background: none;
    width: 100px;
    height: 35px;
    transition: all 0.1s ease;

    &:hover {
      cursor: pointer;
      scale: 1.1;
    }

    &:active {
      opacity: 0.5;
    }
  }

  .popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 20px;
    border: 1px solid #ccc;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    z-index: 1000;
  }
}
