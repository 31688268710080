@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100..900&display=swap");
// font-family
$Roboto: "Roboto", sans-serif;
$MainText: "Noto Sans JP", sans-serif;

// font-size
$SmallSize: 12px;
$MainSize: 16px;
$fz20: 20px;
$fz24: 24px;
$fzH2: 36px;
$fz46: 46px;

//font-weight
$Normal: 400;
$Bold: 700;

// margin, padding

$MSections: 50px;
$MText: 20px;

$PaddingMobile: 0 15px;
$PaddingTablet: 0 30px;
