@import "../../assets/scss/Colors.module.scss";
@import "../../assets/scss/Fonts.module.scss";

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 10px 13px -7px $Shadow;
  position: relative;
  padding: 15px;
  background: linear-gradient(135deg, #ffeafe 0%, #ffd8fb 50%, #ec9fde 100%);
  transition: transform 0.3s ease;
  position: relative;
  height: 400px;
  min-width: 250px;

  h3 {
    font-size: $fz20;
    font-weight: $Bold;
    text-align: center;
    transition: transform 0.3s ease;
    transform-origin: center bottom;
    position: absolute;
    top: 110px;
    line-height: 1;
  }

  p {
    color: $Grey;
    transition: transform 0.3s ease;
    transform-origin: center top;
    position: absolute;
    top: 160px;
  }

  .menu {
    list-style: none;
    padding-top: 100px;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s ease;
    text-align: center;

    li {
      padding: 2px 0;
    }

    .price {
      text-align: center;
      margin-top: 10px;
      font-weight: $Bold;
    }
  }

  .button {
    margin-bottom: 15px;
  }

  &:hover {
    transform: translateY(-20px);
  }

  &:hover .menu {
    visibility: visible;
    opacity: 1;
  }
  &:hover h3,
  &:hover p {
    transform: translateY(-80px);
  }
}

@media screen and (max-width: 520px) {
  .card {
    &:hover {
      transform: translateY(0px);
    }
  }
}
