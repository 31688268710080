@import "../../assets/scss/Colors.module.scss";
@import "../../assets/scss/Fonts.module.scss";

.reviews {
  background: $BackgroundCard;
  .container {
    width: 1280px;
    margin: 0 auto;

    .card_container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: $MSections;
      transition: transform 0.8s ease-in-out;
      .buttons {
        .button {
          padding: 5px 10px;
          border: none;
          background: $BackgroundCard;
          color: $Grey;
          font-size: $fz46;
          transition: all 0.5s ease;
          text-shadow: -1px -1px 1px rgba(255, 255, 255, 0.1),
            1px 1px 1px rgba(0, 0, 0, 0.5);

          &:hover {
            cursor: pointer;
          }

          &:active {
            color: $HoverColor;
          }
        }
      }
    }
  }
}

// Mobile
@media screen and (max-width: 520px) {
  .reviews {
    .container {
      width: 100%;
    }
  }
}

//  Tablet SD
@media screen and (min-width: 521px) and (max-width: 768px) {
  .reviews {
    .container {
      width: 100%;
    }
  }
}

// Tablet HD
@media screen and (min-width: 769px) and (max-width: 1350px) {
  .reviews {
    .container {
      width: 100%;
    }
  }
}
