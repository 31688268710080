@import "../../assets/scss/Colors.module.scss";
@import "../../assets/scss/Fonts.module.scss";

header {
  background-color: $Background;
  position: sticky;
  top: 0;
  z-index: 1000;
  box-shadow: 0px 4px 15px -2px $Grey;

  .container {
    display: flex;
    width: 1280px;
    margin: 0 auto;
    justify-content: space-between;
    align-items: center;

    .logo {
      width: 75%;
      max-height: 70px;
    }

    .buttonLang {
      border: none;
      margin: 5px;
      border-radius: 20px;
      padding: 0 1vi;
      color: $HoverColor;
      background: $BackgroundPink;
      cursor: pointer;
      box-shadow: 0 2px 5px 0 $Shadow;
      transition: all 0.3s ease;

      &:hover {
        background: $SubmitHover;
      }

      &:active {
        background: $Submit;
        box-shadow: inset 0 2px 5px 0 $Shadow;
      }
    }
    .selected {
      background-color: $Submit;
    }

    .menu {
      display: flex;

      li {
        list-style: none;

        &:not(:first-child) button {
          margin-left: 30px;
        }

        button {
          border: none;
          background-color: $Background;
          color: $MainColor;
          font-size: $fz20;
          font-weight: $Bold;
          cursor: pointer;

          transition: 0.4s ease;

          &:hover {
            color: $HoverColor;
            box-shadow: 0 2px 0 0 $MainColor;
          }
        }
      }
    }

    .mobile {
      display: none;
      .gamburger {
        margin: 20px;
      }
    }
  }
}

// Mobile
@media screen and (max-width: 520px) {
  header {
    .container {
      width: 100%;

      .menu {
        display: none;
      }
      .gamburger {
        position: relative;
        margin: 20px;

        &:hover {
          cursor: pointer;
        }
        &:active {
          color: $Grey;
        }
      }

      .mobile {
        display: block;

        .menu_mobile {
          display: none;
          transition: all 0.3s ease;

          flex-direction: column;
          position: absolute;
          top: 58px;
          right: 40px;
          display: flex;
          width: 150px;
          background: $Background;
          box-shadow: 0px 4px 15px -2px $Grey;

          li {
            list-style: none;
            button {
              border: none;
              background-color: $Background;
              color: $MainColor;
              cursor: pointer;

              transition: 0.4s ease;

              &:hover {
                color: $HoverColor;
              }
            }
          }
        }
      }
    }
  }
}

//  Tablet SD
@media screen and (min-width: 521px) and (max-width: 768px) {
  header {
    .container {
      width: 100%;

      .menu {
        display: none;
      }
      .gamburger {
        position: relative;
        margin: 20px;

        &:hover {
          cursor: pointer;
        }
        &:active {
          color: $Grey;
        }
      }

      .mobile {
        display: block;

        .menu_mobile {
          display: none;
          transition: all 0.3s ease;

          flex-direction: column;
          position: absolute;
          top: 58px;
          right: 40px;
          display: flex;
          width: 170px;
          background: $Background;
          box-shadow: 0px 4px 15px -2px $Grey;

          li {
            list-style: none;
            button {
              border: none;
              background-color: $Background;
              color: $MainColor;
              font-weight: $Bold;
              font-size: $fz20;
              cursor: pointer;

              transition: 0.4s ease;

              &:hover {
                color: $HoverColor;
              }
            }
          }
        }
      }
    }
  }
}

// Tablet HD
@media screen and (min-width: 769px) and (max-width: 1350px) {
  header {
    .container {
      width: 100%;

      .menu {
        padding-right: 30px;
      }
    }
  }
}
