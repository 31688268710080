@import "../../assets/scss/Colors.module.scss";
@import "../../assets/scss/Fonts.module.scss";

main {
  background: url(../../assets/images/main.jpg) no-repeat;
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-position-y: 18%;
  position: relative;

  .container {
    width: 1280px;
    margin: 0 auto;
    .title {
      position: absolute;
      top: 100px;
      width: 420px;
      font-size: $fz46;
      font-weight: $Bold;
      color: $MainColor;
    }

    .text {
      position: absolute;
      top: 330px;
      width: 380px;
      font-size: $fz24;
      font-weight: $Normal;
      color: $HoverColor;
    }

    .button {
      position: absolute;
      top: 75%;
      color: $Background;
      background: $MainColor;
      border: none;
      height: 60px;
      font-weight: $Bold;
      text-align: center;
      padding-left: 60px;
      padding-right: 60px;
      border-radius: 15px;
      cursor: pointer;
      transition: all 0.4s ease;

      &:hover {
        background: $Background;
        color: $MainColor;
        border: 1px solid $MainColor;
      }

      &:active {
        background: $ActiveColor;
      }
    }
  }

  &.popupOpen .title,
  &.popupOpen .text {
    opacity: 0.5;
    transition: opacity 0.3s ease;
  }
}

// Mobile
@media screen and (max-width: 520px) {
  main {
    .container {
      padding: $PaddingMobile;
      .title {
        top: 4%;
        font-size: $fzH2;
        width: 280px;
      }
      .text {
        top: 250px;
        width: 250px;
        font-size: $fz20;
      }
    }
  }
}

//  Tablet SD
@media screen and (min-width: 521px) and (max-width: 768px) {
  main {
    .container {
      padding: $PaddingTablet;
      .title {
        top: 3%;
        font-size: $fzH2;
      }
      .text {
        top: 250px;
      }
    }
  }
}

// Tablet HD
@media screen and (min-width: 769px) and (max-width: 1350px) {
  main {
    .container {
      padding: $PaddingTablet;
      .title {
        font-size: $fzH2;
      }
      .text {
        top: 270px;
      }
    }
  }
}
