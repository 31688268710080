@import "../../assets/scss/Colors.module.scss";
@import "../../assets/scss/Fonts.module.scss";

.about_me {
  background: $BackgroundPink;
  font-family: $MainText;

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 1280px;
    margin: 0 auto;

    figure {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      text-align: justify;
      .image {
        width: 200px;
      }
      figcaption {
        padding: 0 20px;
        line-height: 1.5;

        p {
          padding-top: 25px;
        }
      }

      &:nth-child(2) {
        flex-direction: row-reverse;
        margin-top: 20px;
      }
    }
    .documents {
      display: flex;
      justify-content: space-around;
      padding: 50px 0;

      img {
        height: 400px;
      }
    }
  }
}

// Mobile
@media screen and (max-width: 520px) {
  .about_me {
    .container {
      width: 100%;
      figure {
        flex-direction: column;
        align-items: center;

        .image {
          margin-bottom: 20px;
        }

        &:nth-child(2) {
          flex-direction: column;
          margin-top: 20px;
        }
      }

      .documents {
        img {
          width: 80%;
          max-height: 500px;
          padding: 10px 30px;
        }
      }
    }
  }
}

//  Tablet SD
@media screen and (min-width: 521px) and (max-width: 768px) {
  .about_me {
    .container {
      width: 100%;
      figure {
        flex-direction: column;
        align-items: center;

        .image {
          margin-bottom: 20px;
        }

        &:nth-child(2) {
          flex-direction: column;
          margin-top: 20px;
        }
      }

      .documents {
        img {
          width: 60%;
        }
      }
    }
  }
}

// Tablet HD
@media screen and (min-width: 769px) and (max-width: 1350px) {
  .about_me {
    .container {
      width: 100%;

      figure {
        padding: $PaddingTablet;
      }

      .documents {
        flex-wrap: wrap;
        img {
          padding: 10px 30px;
        }
      }
    }
  }
}
