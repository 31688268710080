@import "../../assets/scss/Colors.module.scss";
@import "../../assets/scss/Fonts.module.scss";

.services {
  background: no-repeat url("../../assets/images/background.png");
  background-size: 20%;
  background-position: right top;

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 1280px;
    margin: 0 auto;

    p.text {
      font-size: $fz24;
      font-weight: $Bold;
      color: $HoverColor;
      margin-bottom: $MText;
      line-height: 1.5;
      width: 65%;
      text-align: center;
    }

    .card_container {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: repeat(auto-fill, minmax(350px, 1fr));
      gap: 16px;
      box-sizing: border-box;
      margin: 50px 0;
    }
  }
}

// Mobile
@media screen and (max-width: 520px) {
  .services {
    background-size: 30%;
    .container {
      width: 100%;

      p.text {
        font-size: $fz20;
      }

      .card_container {
        grid-template-columns: repeat(1, 1fr);
      }
    }
  }
}

//  Tablet SD
@media screen and (min-width: 521px) and (max-width: 768px) {
  .services {
    .container {
      width: 100%;

      p.text {
        font-size: $fz20;
      }

      .card_container {
        grid-template-columns: repeat(2, 1fr);
        padding: $PaddingTablet;
        gap: 30px;
      }
    }
  }
}

// Tablet HD
@media screen and (min-width: 769px) and (max-width: 1350px) {
  .services {
    .container {
      width: 100%;

      p.text {
        font-size: $fz20;
        width: 60%;
      }

      .card_container {
        grid-template-columns: repeat(2, 1fr);
        padding: $PaddingTablet;
        gap: 50px;
      }
    }
  }
}
