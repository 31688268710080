@import "../../assets/scss/Colors.module.scss";
@import "../../assets/scss/Fonts.module.scss";

.contacts {
  .container {
    background: no-repeat url("../../assets/images/back.png");
    background-size: 25%;
    background-position: left 10% bottom;
    width: 1280px;
    margin: 0 auto;

    h3 {
      text-align: center;
      font-size: $fz20;
      margin-bottom: $MSections;
    }
    .c_container {
      display: flex;
      flex-direction: column-reverse;
      padding: $PaddingMobile;

      .social {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: $MSections;

        .tel,
        .email {
          text-decoration: none;
          color: $MainColor;

          &:hover {
            color: $HoverColor;
          }
          &:active {
            color: $ActiveColor;
          }
        }

        .social_media {
          a {
            svg {
              font-size: $fz24;
              padding: 5px 10px;
              margin: 30px 7px;
              animation: animate 3s linear infinite;

              &:hover {
                transform: scale(1.1);
              }
            }
          }
          .instagram {
            animation-delay: 2s;
          }

          .telegram {
            animation-delay: 2s;
          }

          .facebook {
            animation-delay: 2s;
          }
          @keyframes animate {
            from {
              filter: hue-rotate(0deg);
            }
            to {
              filter: hue-rotate(360deg);
            }
          }
        }
      }
    }
  }
}

// Mobile
@media screen and (max-width: 520px) {
  .contacts {
    .container {
      background: none;
      width: 100%;

      .c_container {
        .social {
          margin-top: $MText;
        }
      }
    }
  }
}

//  Tablet SD
@media screen and (min-width: 521px) and (max-width: 768px) {
  .contacts {
    .container {
      background-size: 20%;
      width: 100%;

      .c_container {
        padding: $PaddingTablet;
        align-items: center;

        .social {
          margin-top: $MText;
        }
      }
    }
  }
}

// Tablet HD
@media screen and (min-width: 769px) and (max-width: 1350px) {
  .contacts {
    .container {
      width: 100%;

      .c_container {
        padding: $PaddingTablet;
      }
    }
  }
}
